/* Success message */
.success-message {
    background-color: #d4edda;
    color: #155724;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  /* Error message */
  .error-message {
    background-color: #f8d7da;
    color: #721c24;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  /* Close button */
  .close {
    float: right;
    font-size: 20px;
    cursor: pointer;
  }
  
  /* Clearfix */
  .clearfix::after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* ErrorPopup.css */

.error-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f44336;
  color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.error-popup-content {
  text-align: center;
}

.close-icon {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
}

/* SuccessPopup.css */

.success-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4caf50;
  color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.success-popup-content {
  text-align: center;
}

.close-icon {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
}
