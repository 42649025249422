.portfolioBanner {
    background-image: url("../../assets/Images/Portfolio/PortfolioBanner.png");
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    padding: 180px 0px;
    background-attachment: fixed;
}


.smBanner {
    background-image: url("../../assets/Images/Portfolio/AIScrum.png");
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    padding: 180px 0px;
    background-attachment: fixed;
}

.bloc-tabs {
    display: flex;
    background-color: #ffffff;
    border-radius: var(--border-radius);
    border: 1px solid #f5f5f5;
    margin-top: 50px;
    margin-bottom: 10px;
}

.tabs {
    padding: 15px;
    text-align: center;
    width: 50%;
    background: transparent;
    cursor: pointer;
    position: relative;
    outline: none;
    font-size: 20px;
}

.tabs:hover {
    color: var(--theme-main-orange);
}

.active-tabs {
    background: transparent;
    color: var(--theme-main-orange);
}

.active-tabs::before {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(60% + 2px);
    height: 5px;
    background: var(--theme-main-orange);
    border-radius: 0px;
}

button {
    border: none;
}

.content-tabs {
    flex-grow: 1;
}

.content {
    background: white;
    padding: 10px 0px 0px 0px;
    width: 100%;
    height: 100%;
    display: none;
}

.portfolio .card {
    border: none;
    box-shadow: 0 0 10px #e2e1e1;
    margin-bottom: 25px;
    border-radius: 0px;
}

.portfolio .card .card-img-top {
    border-radius: 0px 0px 0px 0px;
}

.portfolio .card-title {
    font-size: 22px;
    color: var(--theme-main-orange);
    cursor: pointer;
    transition: 0.4s ease;
}

.portfolio .card-title:hover {
    color: #000;
}

.content p {
    width: 100%;
    height: 100%;
    line-height: 28px;
    font-size: 17px;
}

.active-content {
    display: block;
}

.portfolio .beffect .bimg::after {
    border-radius: 0px;
}


/* ********************************************Responsive************************************************ */

@media (min-width: 1200px) and (max-width: 1410px) {}


/* ***************************************************** 992 - 1199 ***************************************************** */

@media (min-width: 992px) and (max-width: 1199px) {
    .tabs {
        font-size: 18px;
    }
}


/* ***************************************************** 768 - 991 ***************************************************** */

@media (min-width: 768px) and (max-width: 991px) {
    .content p {
        line-height: 20px;
        font-size: 16px;
    }
    .tabs {
        padding: 8px;
        font-size: 18px;
    }
    .card-title {
        font-size: 18px;
    }
    .active-tabs::before {
        height: 4px;
    }
}


/* ***************************************************** 576 - 767 ***************************************************** */

@media (min-width: 576px) and (max-width: 767px) {
    .content p {
        line-height: 20px;
        font-size: 14px;
    }
    .card-title {
        font-size: 17px;
    }
    .bloc-tabs {
        flex-wrap: wrap;
    }
    .tabs {
        font-size: 17px;
        padding: 7px;
    }
}


/* ***************************************************** 320 - 575 ***************************************************** */

@media (min-width: 320px) and (max-width: 575px) {
    .bloc-tabs {
        flex-wrap: wrap;
        margin-top: 30px;
    }
    .tabs {
        padding: 8px;
        font-size: 15px;
    }
    .active-tabs::before {
        height: 3px;
    }
    .content {
        padding: 0px;
    }
    .card-title {
        font-size: 18px;
        margin: 0;
    }
    .content p {
        line-height: 20px;
        font-size: 14px;
    }
}